/* eslint-disable comma-dangle */
export const VIA = "via";
export const POWERAPPS = "powerapps";
export const IRL = "irl";
export const TPRP = 'tprp'
/**
 * dev env
 * via-demo.ey.com
 * eyvia-dev.sbp.eyclienthub.com
 */

const viaHosts = {
  // VIA prod
  'eyvia.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    stage: 'prd',
  },
  'eyvia-jp.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    stage: 'prd',
  },
  'eyvia-de.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    stage: 'prd',
  },
  'eyvia-us.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    stage: 'prd',
  },
  'eyvia-chn.ey.com.cn': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    stage: 'prd',
  },
  'eyvia-au.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    stage: 'prd',
  },
  'eyvia-ca.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    stage: 'prd',
  },
  'eyvia-za.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    stage: 'prd',
  },
  'eyvia-uae.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-training.ey.com',
    powerappService: 'https://eyaccelerators-training.ey.com',
    stage: 'prd',
  },
  'eyvia-training.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-training.ey.com',
    powerappService: 'https://eyaccelerators-training.ey.com',
    stage: 'prd',
  },
  "eyvia-qa2.sbp.eyclienthub.com": {
    powerappFrontEnd: "https://eyaccelerators-dev.ey.com",
    powerappService: "https://eyaccelerators-dev.ey.com",
    stage: "stg"
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy', // 'w1Uh07j6sKG51qJ7Sp7E1QAwPbQAVoUf',
  },

  // VIA dev
  "eyvia-qa.sbp.eyclienthub.com": {
    powerappFrontEnd: "https://eyaccelerators-dev.ey.com",
    powerappService: "https://eyaccelerators-dev.ey.com",
    stage: "stg"
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  "eyvia-dev1.ey.com": {
    powerappFrontEnd: "https://eyaccelerators-dev.ey.com",
    powerappService: "https://eyaccelerators-dev.ey.com",
    stage: "stg"
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  "eyvia-qa1.ey.com": {
    powerappFrontEnd: "https://eyaccelerators-dev.ey.com",
    powerappService: "https://eyaccelerators-dev.ey.com",
    stage: "stg"
    // apiKey: '4zgZqiElxFBO737yNCFquCvH04hjQGIy',
  },
  "via-demo.ey.com": {
    powerappFrontEnd: "https://eyaccelerators-dev.ey.com",
    powerappService: "https://eyaccelerators-dev.ey.com",
    stage: "dev"
  },
  "localhost:3000": {
    powerappFrontEnd: "http://localhost:3002",
    powerappService: "http://localhost:8804",
    stage: "dev"
  },
  "eyvia-stg.ey.com": {
    powerappFrontEnd: "https://eyaccelerators-uat.ey.com",
    powerappService: "https://eyaccelerators-uat.ey.com",
    stage: "stg"
  },
  "eyvia-us-staging.ey.com": {
    powerappFrontEnd: "https://eyaccelerators-uat.ey.com",
    powerappService: "https://eyaccelerators-uat.ey.com",
    stage: "stg"
    // apiKey: 'aIMvHGyMeV1yOlXELPVSCfbuguPHWjex', // w1Uh07j6sKG51qJ7Sp7E1QAwPbQAVoUf
  },
  "eyvia-dev.sbp.eyclienthub.com": {
    powerappFrontEnd: "https://eyaccelerators-dev.ey.com",
    powerappService: "https://eyaccelerators-dev.ey.com",
    stage: "dev"
  },
};

const powerappHosts = {
  "localhost:3002": {
    eyviaFrontend: "http://localhost:3000",
    stage: "dev"
  },
  "eyaccelerators-dev.ey.com": {
    eyviaFrontend: "https://eyvia-dev.sbp.eyclienthub.com",
    powerappService: "https://eyaccelerators-dev.ey.com",
    appInsightsKey: "12700b9e-a7cf-4435-91f2-bd0ef7fd512e",
    stage: "dev"
  },
  "eyaccelerators-uat.ey.com": {
    eyviaFrontend: "https://eyvia-us-staging.ey.com",
    powerappService: "https://eyaccelerators-uat.ey.com",
    appInsightsKey: "e9fc0c26-340d-46fc-85f9-03d284fbe273",
    stage: "stg"
    // apiKey: 'w1Uh07j6sKG51qJ7Sp7E1QAwPbQAVoUf',
  }
};

export const irlHosts = {
  // Eracm prod
  'irl-us.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    acceleratorApigeePath: '/eyacceleratorseuwacl/acl/api',
    eracmPath: '/eracmus/rcl/api',
    stage: 'prd'
  },
  'irl-au.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    acceleratorApigeePath: '/eyacceleratorseuwacl/acl/api',
    eracmPath: '/eracmau/rcl/api',
    stage: 'prd'
  },
  'irl-training.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-training.ey.com',
    powerappService: 'https://eyaccelerators-training.ey.com',
    acceleratorApigeePath: '/eyacceleratorsuanacl/acl/api',
    eracmPath: '/eracmtraining/rcl/api',
    stage: 'prd'
  },
  'irl-za.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    acceleratorApigeePath: '/eyacceleratorseuwacl/acl/api',
    eracmPath: '/eracmza/rcl/api',
    stage: 'prd'
  },
  'irl.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    acceleratorApigeePath: '/eyacceleratorseuwacl/acl/api',
    eracmPath: '/eracm/rcl/api',
    stage: 'prd'
  },
  // Eracm UAT
  'irl-uat.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-uat.ey.com',
    powerappService: 'https://eyaccelerators-uat.ey.com',
    acceleratorApigeePath: '/eyacceleratorsuat/acl/api',
    eracmPath: '/eracm/rcl/api',
    stage: 'stg'
  },
  // Eracm DEV
  'irl-dev.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-dev.ey.com',
    powerappService: 'https://eyaccelerators-dev.ey.com',
    acceleratorApigeePath: '/eyacceleratorsdevacl/acl/api',
    eracmPath: '/eracm/rcl/api',
    stage: 'dev'
  },
  'irl-sandbox.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-dev.ey.com',
    powerappService: 'https://eyaccelerators-dev.ey.com',
    acceleratorApigeePath: '/eyacceleratorsdevacl/acl/api',
    eracmPath: '/irlsandbox/rcl/api',
    stage: 'dev'
  },
  'localhost:3001': {
    powerappFrontEnd: 'http://localhost:3002',
    powerappService: 'http://localhost:3002',
    acceleratorApigeePath: '/eyaccelerators/api',
    eracmPath: '/eracm/api',
    stage: 'dev'
  }
}

export const tprpHosts = {
  'tprp-dev.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-dev.ey.com',
    powerappService: 'https://eyaccelerators-dev.ey.com',
    acceleratorApigeePath: '/eyacceleratorsdevacl/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'dev'
  },
  'tprp-qa.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-qa.ey.com',
    powerappService: 'https://eyaccelerators-qa.ey.com',
    acceleratorApigeePath: '/eyacceleratorsqa/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'dev'
  },
  'tprp-pt.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-qa2.ey.com',
    powerappService: 'https://eyaccelerators-qa2.ey.com',
    acceleratorApigeePath: '/eyacceleratorsdevacl/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'dev'
  },
  'tprp-sit.eu.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-qa.ey.com',
    powerappService: 'https://eyaccelerators-qa.ey.com',
    acceleratorApigeePath: '/eyacceleratorsqa/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'dev'
  },
  'tprp-sit.we.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-qa.ey.com',
    powerappService: 'https://eyaccelerators-qa.ey.com',
    acceleratorApigeePath: '/eyacceleratorsqa/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'dev'
  },

  'tprp-sit.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-qa.ey.com',
    powerappService: 'https://eyaccelerators-qa.ey.com',
    acceleratorApigeePath: '/eyacceleratorsqa/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'dev'
  },

  'tprp-uat.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-uat.ey.com',
    powerappService: 'https://eyaccelerators-uat.ey.com',
    acceleratorApigeePath: '/eyacceleratorsuat/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'stg'
  },

  'tprp.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    acceleratorApigeePath: '/eyacceleratorseuwacl/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'prd'
  },

  'tprp-eu.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    acceleratorApigeePath: '/eyacceleratorseuwacl/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'prd'
  },

  'tprp-we.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators.ey.com',
    powerappService: 'https://eyaccelerators.ey.com',
    acceleratorApigeePath: '/eyacceleratorseuwacl/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'prd'
  },

  'tprp-sandbox.ey.com': {
    powerappFrontEnd: 'https://eyaccelerators-uat.ey.com',
    powerappService: 'https://eyaccelerators-uat.ey.com',
    acceleratorApigeePath: '/eyacceleratorsuat/acl/api',
    tokenApiPath: '/tprp/api',
    stage: 'prd'
  },

  'localhost:3000': {
    powerappFrontEnd: 'http://localhost:3002',
    powerappService: 'http://localhost:3002',
    acceleratorApigeePath: '/eyaccelerators/api',
    tokenApiPath: '/tprp/api',
    stage: 'dev'
  }
}

export const getIrlApiPath = (host) => {
  if (irlHosts[host]) {
    return irlHosts[host].eracmPath;
  }
  return null;
}

export const getTPRPTokenApiPath = (host) => {
  if (tprpHosts[host]) {
    return tprpHosts[host].tokenApiPath;
  }
  return null;
}

export const getMicroFeDependency = (host) => {
  if (powerappHosts[host]) {
    return powerappHosts[host].eyviaFrontend;
  } else if (viaHosts[host]) {
    return viaHosts[host].powerappFrontEnd;
  } else if (irlHosts[host]) {
    return irlHosts[host].powerappFrontEnd;
  } else if (tprpHosts[host]) {
    return tprpHosts[host].powerappFrontEnd;
  }
  return window.location.origin;
};

export const getRiskAcceleratorDependency = (host) => {
  if (powerappHosts[host]) {
    return powerappHosts[host].powerappService;
  } else if (viaHosts[host]) {
    return viaHosts[host].powerappService;
  } else if (irlHosts[host]) {
    return irlHosts[host].powerappService;
  }else if (tprpHosts[host]) {
    return tprpHosts[host].powerappService;
  }
  return null;
};

export const getRiskAcceleratorInsightsInfo = (host) => {
  if (powerappHosts[host]) {
    const { appInsightsKey = null, stage = "" } = powerappHosts[host];
    return {
      appInsightsKey,
      stage
    };
  }
  return { appInsightsKey: null, stage: "" };
};

export const getAppName = (host) => {
  if (powerappHosts[host]) {
    return POWERAPPS;
  } else if (viaHosts[host]) {
    return VIA;
  } else if (irlHosts[host]) {
    return IRL;
  } else if (tprpHosts[host]) {
    return TPRP
  }
  return null;
};
